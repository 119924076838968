<template>
  <div class="container">
    <div class="filter-container">
      <el-button
          class="filter-item"
          type="primary"
          icon="el-icon-plus"
          size="mini"
          style="margin-bottom:10px"
          @click="handleCreate"
      >添加</el-button>
    </div>
    <el-table
        v-loading="listLoading"
        :data="list"
        border
        fit
        highlight-current-row
        style="width: 100%;"
    >
      <el-table-column label="书屋名称" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="书屋负责人" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrContactName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="图书条码长度" align="center">
        <template v-slot="{row}">
          <span>{{ row.IntBookBarcodeLength }}</span>
        </template>
      </el-table-column>
      <el-table-column label="最大续借次数" align="center">
        <template v-slot="{row}">
          <span>{{ row.IntMaxRenewCount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="图书默认借阅天数" align="center">
        <template v-slot="{row}">
          <span>{{ row.IntDefaultBorrowDays }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
          fixed="right"
          width="200px"
      >
        <template v-slot="{row}">
          <el-button type="primary" size="mini" @click="handleUpdate(row)">编辑</el-button>
          <el-button
              size="mini"
              type="danger"
              @click="handleDelete(row)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div style="margin-top:50px;text-align:right;">
      <el-pagination
          background
          :total="total"
          :current-page="listQuery.Page"
          :page-size="listQuery.Perpage"
          :page-sizes="[5, 10, 20, 30, 40, 50, 100]"
          layout="prev,pager,next,jumper,sizes,total"
          @size-change="pageSizeChange"
          @current-change="currPageChange"
      />
    </div>
<!--    添加-->
    <el-dialog width="32%" :title="textMap[dialogStatus]" v-model="logLibraryModel" :close-on-click-modal="false">
      <el-form
          ref="dataForm"
          :rules="rules"
          :model="formModel"
          label-position="left"
          label-width="100px"
          style="margin-left:50px;"
          size="small"
      >
        <el-row :gutter="20">

          <el-col :xs="xsSize" :sm="xsSize" :md="xsSize" :lg="xsSize" :xl="xsSize">
            <el-form-item label="书屋名称" prop="StrName">
              <el-input v-model="formModel.StrName" />
            </el-form-item>
          </el-col>
          <el-col :xs="xsSize" :sm="xsSize" :md="xsSize" :lg="xsSize" :xl="xsSize">
            <el-form-item label="书屋位置" prop="StrLibraryLocation">
              <el-input v-model="formModel.StrLibraryLocation" />
            </el-form-item>
          </el-col>
          <el-col :xs="xsSize" :sm="xsSize" :md="xsSize" :lg="xsSize" :xl="xsSize">
            <el-form-item label="联系人" prop="StrContactName">
              <el-input v-model="formModel.StrContactName" />
            </el-form-item>
          </el-col>
          <el-col :xs="xsSize" :sm="xsSize" :md="xsSize" :lg="xsSize" :xl="xsSize">
            <el-form-item label="联系电话" prop="StrContactNumber">
              <el-input v-model="formModel.StrContactNumber" />
            </el-form-item>
          </el-col>
          <el-col :xs="xsSize" :sm="xsSize" :md="xsSize" :lg="xsSize" :xl="xsSize">
            <el-form-item label="条码长度" prop="IntBookBarcodeLength">
              <el-input v-model="formModel.IntBookBarcodeLength" />
            </el-form-item>
          </el-col>
          <el-col :xs="xsSize" :sm="xsSize" :md="xsSize" :lg="xsSize" :xl="xsSize">
            <el-form-item label="备注" prop="strRemark">
              <el-input v-model="formModel.StrRemark" type="textarea" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="dialog-footer" style="float: right;border: 10px">
        <el-button size="mini" @click="logLibraryModel = false">取消</el-button>
        <el-button type="primary" size="mini" @click="btnAddorMod">确定</el-button>
      </div>
      <p style="color: #ffffff">三阅</p>
    </el-dialog>

<!--    编辑-->
    <el-dialog :title="'编辑'" width="55%" v-model="LibraryformModel" top="7vh" :close-on-click-modal="false">
    <el-form ref="librarymodel" label-width="150px" size="small" :model="LibraryModel" :rules="rules">
      <el-row>

        <el-col :span="12">
          <el-form-item label="书屋名称" prop="StrName">
            <el-input v-model="LibraryModel.StrName" placeholder="请填写书屋名称" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="书屋位置" >
            <el-input v-model="LibraryModel.StrLibraryLocation" placeholder="请填写书屋位置" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="书屋负责人">
            <el-input v-model="LibraryModel.StrContactName"  placeholder="请填写书屋负责人" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系方式">
            <el-input v-model="LibraryModel.StrContactNumber" placeholder="请填写负责人的联系方式" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="积分" prop="IntIntegral">
            <el-input v-model="LibraryModel.IntIntegral" placeholder="请填写积分" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="最少借阅天数" prop="IntMinBorrowDays">
            <el-input v-model="LibraryModel.IntMinBorrowDays" placeholder="请填写获得积分的最少借阅天数" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="最大借阅天数" prop="IntMaxBorrowDays">
            <el-input v-model="LibraryModel.IntMaxBorrowDays" placeholder="请填写获得积分的最大借阅天数" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="图书条码长度" prop="IntBookBarcodeLength">
            <el-input v-model="LibraryModel.IntBookBarcodeLength" placeholder="请填写图书条码长度" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="最大续借次数" prop="IntMaxRenewCount">
            <el-input v-model="LibraryModel.IntMaxRenewCount" placeholder="请填写最大续借次数" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="默认借阅天数" prop="IntDefaultBorrowDays">
            <el-input v-model="LibraryModel.IntDefaultBorrowDays" placeholder="请填写最大续借次数" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="图书超期罚款(元/天)" prop="FloatBookOverdueFineAmount">
            <el-input v-model="LibraryModel.FloatBookOverdueFineAmount" placeholder="请填写图书超期罚款" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="期刊超期罚款(元/天)" prop="FloatJournalOverdueFineAmount">
            <el-input v-model="LibraryModel.FloatJournalOverdueFineAmount" placeholder="请填写卷期刊损坏罚款" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="借阅公告">
            <el-input type="textarea" rows="5" v-model="LibraryModel.StrNotice" placeholder="请填写借阅公告" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
        <span class="dialog-footer" style="float: right;">
        <el-button size="mini" @click="LibraryformModel = false">取消</el-button>
        <el-button type="primary" size="mini" @click="btnAddorMod">确定</el-button>
      </span>
        </el-col>
      </el-row>
    </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name:'library',
  data() {
    return {
      list: [],
      total: 0,
      listLoading: true,
      listQuery: {
        Page: 1,
        Perpage: 10,
        intNotOn:"1",
        Sort:"-1",
        OrderBy: "DTCreatedDate",
      },
      dialogFormVisible: false,
      logLibraryModel:false,
      LibraryformModel:false,
      LibraryModel:{},
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '添加'
      },
      dialogPvVisible: false,
      rules: {
        IntIntegral: [
          { required: true, message: '请输入积分', trigger: 'blur' }
        ],
        StrName: [
          { required: true, message: '请输入书屋名称', trigger: 'blur' }
        ],
        StrLibraryLocation: [
          { required: true, message: '请输入书屋位置', trigger: 'blur' }
        ],
        IntBookBarcodeLength: [
          { required: true, message: '请输入图书条码长度', trigger: 'blur' }
        ],
        IntMinBorrowDays: [
          { required: true, message: '请输入获得积分的最少借阅天数', trigger: 'blur' }
        ],
        IntMaxBorrowDays: [
          { required: true, message: '请输入获得积分的最大借阅天数', trigger: 'blur' }
        ],
        IntMaxRenewCount: [
          { required: true, message: '请输入最大借阅次数', trigger: 'blur' }
        ],
        IntDefaultBorrowDays: [
          { required: true, message: '请输入默认借阅天数', trigger: 'blur' }
        ],
        FloatBookLostFineMultiple: [
          { required: true, message: '请输入图书丢失罚款', trigger: 'blur' }
        ],
        FloatBookOverdueFineAmount: [
          { required: true, message: '请输入图书超期罚款', trigger: 'blur' }
        ],
        FloatJournalLostFineMultiple: [
          { required: true, message: '请输入期刊丢失罚款', trigger: 'blur' }
        ],
        FloatJournalOverdueFineAmount: [
          { required: true, message: '请输入期刊超期罚款', trigger: 'blur' }
        ],
      },
      xsSize: 24,
      smSize: 24,
      mdSize: 12,
      lgSize: 12,
      xlSize: 12,
      pageIndex: 1,
      pageSize: 10,
      xxList: [],
      formModel: {
      }
    }
  },
  created() {
    this.getList()
    // this.getXXList()
  },
  methods: {
    // 获取书屋列表
    getList() {
      this.$axios({
        url: this.localpath + 'librarys/search',
        method: 'post',
        data:JSON.stringify(this.listQuery),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.list = res.data.msg
          this.total = res.data.total
          this.listLoading = false
        }
      })
    },
    // 添加
    handleCreate() {
      this.formModel = {}
      this.dialogStatus = 'create'
      this.logLibraryModel = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    // 编辑
    handleUpdate(row) {
      this.dialogStatus = 'update'
      this.LibraryformModel = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
      this.$axios({
        url: this.localpath + 'librarys/'+row.StrID,
        method: 'get',
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then((res) => {
        if (res.data.ok) {
          this.LibraryModel = res.data.msg
        }
      })
    },
    // 确认添加或修改
    btnAddorMod() {
      if (this.dialogStatus === 'create') {
        this.$refs['dataForm'].validate(valid => {
          if (valid) {
            this.formModel.IntBookBarcodeLength=parseInt(this.formModel.IntBookBarcodeLength)
            this.$axios({
              url: this.localpath + 'librarys',
              method: 'put',
              data:JSON.stringify(this.formModel),
              //发送格式为json
              headers:
                  {
                    'Content-Type': 'application/json',
                    'dataType': 'json',
                    'Authorization': localStorage.getItem("token")
                  }
            }).then((res) => {
              if (res.data.ok) {
                this.$message({
                  message: '添加成功',
                  type: 'success'
                })
              }else{
                this.$message({
                  message: res.data.msg,
                  type: 'error'
                })
              }
              this.getList()
            }).catch((res) => {
              this.$message({
                message: res.data.msg,
                type: 'success'
              })
            })
            this.logLibraryModel = false
          }
        })
      } else {
        this.LibraryModel.FloatBookLostFineMultiple= this.LibraryModel.FloatBookLostFineMultiple.toString()
        this.LibraryModel.FloatBookOverdueFineAmount= this.LibraryModel.FloatBookOverdueFineAmount.toString()
        this.LibraryModel.FloatJournalLostFineMultiple= this.LibraryModel.FloatJournalLostFineMultiple.toString()
        this.LibraryModel.FloatJournalOverdueFineAmount= this.LibraryModel.FloatJournalOverdueFineAmount.toString()
        this.LibraryModel.IntIntegral= parseInt(this.LibraryModel.IntIntegral)
        this.LibraryModel.IntMinBorrowDays= parseInt(this.LibraryModel.IntMinBorrowDays)
        this.LibraryModel.IntMaxBorrowDays= parseInt(this.LibraryModel.IntMaxBorrowDays)
        this.LibraryModel.IntBookBarcodeLength= parseInt(this.LibraryModel.IntBookBarcodeLength)
        this.LibraryModel.IntMaxRenewCount= parseInt(this.LibraryModel.IntMaxRenewCount)
        this.LibraryModel.IntDefaultBorrowDays= parseInt(this.LibraryModel.IntDefaultBorrowDays)
        this.$refs['librarymodel'].validate(valid => {
          if (valid) {
            this.$axios({
              url: this.localpath + 'librarys/'+this.LibraryModel.StrID,
              method: 'patch',
              data:JSON.stringify(this.LibraryModel),
              //发送格式为json
              headers:
                  {
                    'Content-Type': 'application/json',
                    'dataType': 'json',
                    'Authorization': localStorage.getItem("token")
                  }
            }).then((res) => {
              if (res.data.ok) {
                this.$message({
                  message: '编辑成功',
                  type: 'success'
                })
              }else{
                this.$message({
                  message: res.data.msg,
                  type: 'success'
                })
              }
              this.LibraryformModel = false
              this.getList()
            }).catch((res) => {
              this.$message({
                message: res.data.msg,
                type: 'success'
              })
            })
          }
        })
      }
    },
    // 删除
    handleDelete(row) {
      this.$confirm('确定要删除该书屋吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        type: "warning",
      }).then(() => {
        this.$axios({
          url: this.localpath + 'librarys/'+row.StrID,
          method: 'delete',
          //发送格式为json
          headers:
              {
                'Content-Type': 'application/json',
                'dataType': 'json',
                'Authorization': localStorage.getItem("token")
              }
        }).then((res) => {
          if (res.data.ok) {
            this.$notify({
              message: '删除成功',
              type: 'success',
              duration: 2000
            })
            this.getList()
          }else{
            this.$notify({
              message: res.data.msg,
              type: 'error',
              duration: 2000
            })
          }
          
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    pageSizeChange(Perpage) {
      this.listQuery.Perpage = Perpage
      this.getList()
    },
    currPageChange(Page) {
      this.listQuery.Page = Page
      this.getList()
    }
  }
}
</script>

